import { createModuleApi } from 'momai'
import SparkMD5 from 'spark-md5'
import { api } from '@/api/useAxios'
import { Toast } from 'vant'

const fileApi = <T> () => createModuleApi<T>('file')

const updateFunc = (cb: (p: number) => void) => (e: any) => {
  if (e.lengthComputable) {
    cb(Math.round(e.loaded * 100 / e.total))
  }
}

export const uploadBigVedio = (file: any, uploadDirectory: any, finishFn = (e: any) => {
}) => {
  let currentChunk = 0
  const chunkSize: number = 10 * 1024 * 1024
  const chunks: number = Math.ceil(file.size / chunkSize)
  let fileMD5 = ''
  const toast = Toast.loading({
    duration: 0, // 持续展示 toast
    forbidClick: true,
    message: '进度 0%'
  })
  const flushToast = () => {
    toast.message = '进度 ' + Number((currentChunk / chunks).toFixed(2)) * 100 + ' %'
  }
  const uploadFn: any = (fileBody: any, readNext: any) => {
    const fd = new FormData()
    // 设置文件上传接口的需要的参数
    fd.append('md5', fileMD5)
    fd.append('chunks', chunks + '')
    fd.append('chunkSize', chunkSize + '')
    fd.append('currentChunk', currentChunk + '')
    fd.append('fileName', file.name)
    fd.append('directory', uploadDirectory)
    if (fileBody != null) {
      fd.append('file', new Blob([fileBody]))
    }
    api.post('/file/chunk', fd, false).then(r => {
      currentChunk++
      if (currentChunk < chunks) {
        readNext()
      } else {
        toast.message = '进度 100%'
        Toast.clear(true)
        finishFn(r)
      }
    }).catch(r => {
      finishFn(null)
    })
  }
  function startUpload (file: any, callback = (e: any) => {
  }) {
    const fileReader = new FileReader()
    let currentChunk = 0

    // 创建md5对象（基于SparkMD5）
    const spark: any = new SparkMD5()
    const readNext = () => {
      const start = currentChunk * chunkSize
      const end = start + chunkSize >= file.size ? file.size : start + chunkSize

      fileReader.readAsBinaryString(file.slice(start, end))
    }
    // 每块文件读取完毕之后的处理
    fileReader.onload = function (e: any) {
      spark.appendBinary(e.target.result)
      currentChunk++

      if (currentChunk < chunks) {
        readNext()
      } else {
        callback(spark.end())
      }
    }

    // 处理单片文件的上传

    readNext()
  }

  function uploadChunk () {
    const fileReader = new FileReader()

    const readNext = () => {
      // 查询后台判断当前块文件有没有上传，如果已经上传则不需要上传，继续读取下一块
      flushToast()
      api.get('/file/chunk', {
        md5: fileMD5,
        currentChunk: currentChunk,
        fileName: file.name,
        chunks: chunks
      }).then(res => {
        if (!res) {
          const start = currentChunk * chunkSize
          const end = Math.min(start + chunkSize, file.size)
          fileReader.readAsArrayBuffer(file.slice(start, end))
        } else {
          currentChunk++
          if (currentChunk < chunks) {
            readNext()
          } else {
            uploadFn(null, readNext)
          }
        }
      })
    }

    fileReader.onload = function (e) {
      uploadFn(this.result, readNext)
    }
    // 处理单片文件的上传

    readNext()
  }

  const checkFile = (md5: string) => {
    fileMD5 = md5
    api.get('/file/chunk', {
      md5,
      name: file.name
    }).then((res) => {
      if (!res) {
        uploadChunk()
      } else { // 秒传
        // 文件上传成功
        currentChunk = chunks
        uploadFn(null, null)
      }
    })
  }
  startUpload(file, checkFile)
}
const uploadImage = (type: string, file: File | Blob, cb = () => {
}) => fileApi<string>()({
  subUrl: 'upload',
  method: 'post',
  params: ((fd = new FormData()) => {
    fd.append('type', type)
    fd.append('files', file)
    return fd
  })(),
  options: {
    onUploadProgress: updateFunc(cb)
  }
})

const uploadVideo = ({
  directory,
  video,
  update = () => {}
}: {
  directory: string;
  video: File;
  update?: (p: number) => void;
}) => fileApi<{
  videoPath: string;
  coverPath: string;
  duration: number;
}>()({
  subUrl: 'upload-video',
  method: 'post',
  params: ((fd = new FormData()) => {
    fd.append('video', video)
    fd.append('directory', directory)
    return fd
  })(),
  options: {
    onUploadProgress: updateFunc(update)
  }
})

export {
  uploadImage,
  uploadVideo
}
